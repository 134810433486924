import {
  CellId,
  CellReferenceMap,
  CellReferencesV2,
  DatetimeOptions,
  DropdownOptions,
  DynamicDataFrameValue,
  DynamicValue,
  DynamicValueTypes,
  MultiSelectOptions,
  NoCodeCellDataframe,
  PythonCellReferencesV2,
  TableOptions,
  VariableName,
  assertNever,
  createEmptyParamReferences,
  getCalcsCellReferencesV2,
  getChartReferencedInputs,
  getMapReferencedDataFrames,
  getNoCodeCellInputReference,
  getPrunedVariableNameList,
  mergeCellReferencesV2,
  safeCastVariableName,
} from "@hex/common";

import { CellContentsMP, CellMP } from "../redux/slices/hexVersionMPSlice";

export const getCellReferencesV2 = (
  cell: CellMP,
  cellContents: CellContentsMP,
): PythonCellReferencesV2 | CellReferencesV2 | undefined => {
  switch (cellContents.__typename) {
    case "CodeCell": {
      const refs = cellContents.cellReferencesV2;
      if (refs != null) {
        return refs;
      }
      break;
    }
    case "DisplayTableCell": {
      const referencedDfNames = new Set<VariableName>();
      const resultVariableNames = new Set<VariableName>();
      if (cellContents.dataframe != null) {
        referencedDfNames.add(
          safeCastVariableName(
            getNoCodeCellInputReference(cellContents.dataframe),
          ),
        );
      }
      if (cellContents.resultVariable.length > 0) {
        resultVariableNames.add(
          safeCastVariableName(cellContents.resultVariable),
        );
      }
      return mergeCellReferencesV2(
        {
          newParams: createEmptyParamReferences([...resultVariableNames]),
          referencedParams: createEmptyParamReferences([...referencedDfNames]),
        },
        cellContents.displayTableConfig.conditionalFormattingReferences,
        cellContents.displayTableConfig.filtersReferences,
        getCalcsCellReferencesV2(cellContents.displayTableConfig?.calcs),
      );
    }
    case "MetricCell": {
      const referencedParams = [];
      const refs = cellContents.cellReferencesV2;
      if (cellContents.cellReferencesV2 != null) {
        referencedParams.push(...(refs?.referencedParams ?? []));
      }
      if (
        cellContents.valueVariableName &&
        cellContents.valueVariableName.length > 0
      ) {
        referencedParams.push(
          ...createEmptyParamReferences([
            safeCastVariableName(cellContents.valueVariableName),
          ]),
        );
      }
      if (
        cellContents.comparisonVariableName &&
        cellContents.comparisonVariableName.length > 0
      ) {
        referencedParams.push(
          ...createEmptyParamReferences([
            safeCastVariableName(cellContents.comparisonVariableName),
          ]),
        );
      }

      const newParams = [];
      if (cellContents.outputResult && cellContents.valueResultVariable) {
        newParams.push(
          ...createEmptyParamReferences([
            safeCastVariableName(cellContents.valueResultVariable),
          ]),
        );
      }
      if (
        cellContents.outputResult &&
        cellContents.showComparison &&
        cellContents.comparisonResultVariable
      ) {
        newParams.push(
          ...createEmptyParamReferences([
            safeCastVariableName(cellContents.comparisonResultVariable),
          ]),
        );
      }
      return { referencedParams, newParams };
    }
    case "MarkdownCell": {
      const refs = cellContents.cellReferencesV2;
      if (refs != null) {
        return refs;
      }
      break;
    }
    case "TextCell": {
      const refs = cellContents.cellReferencesV2;
      if (refs != null) {
        return refs;
      }
      break;
    }
    case "Parameter": {
      const newParams = [];
      const referencedParams = [];
      const options = cellContents.options;

      newParams.push(
        ...createEmptyParamReferences(
          DatetimeOptions.guard(options) && options.useDateRange
            ? [
                safeCastVariableName(cellContents.name + "_start"),
                safeCastVariableName(cellContents.name + "_end"),
              ]
            : [cellContents.name],
        ),
      );

      if (options != null) {
        if (
          DropdownOptions.guard(options) &&
          DynamicValueTypes.guard(options.valueOptions)
        ) {
          // If the cell references a dataframe column,
          // store the dataframe variable name as the reference
          if (
            DynamicDataFrameValue.guard(options.valueOptions) &&
            options.valueOptions.dfName != null
          ) {
            referencedParams.push(
              ...createEmptyParamReferences([
                safeCastVariableName(options.valueOptions.dfName),
              ]),
            );
          } else if (options.valueOptions.variableName != null) {
            referencedParams.push(
              ...createEmptyParamReferences([
                safeCastVariableName(options.valueOptions.variableName),
              ]),
            );
          }
        } else if (
          MultiSelectOptions.guard(options) &&
          DynamicValueTypes.guard(options.multiValueOptions)
        ) {
          if (
            DynamicDataFrameValue.guard(options.multiValueOptions) &&
            options.multiValueOptions.dfName != null
          ) {
            referencedParams.push(
              ...createEmptyParamReferences([
                safeCastVariableName(options.multiValueOptions.dfName),
              ]),
            );
          } else if (options.multiValueOptions.variableName != null) {
            referencedParams.push(
              ...createEmptyParamReferences([
                safeCastVariableName(options.multiValueOptions.variableName),
              ]),
            );
          }
        } else if (
          TableOptions.guard(options) &&
          DynamicValue.guard(options.value) &&
          options.value.variableName != null
        ) {
          referencedParams.push(
            ...createEmptyParamReferences([
              safeCastVariableName(options.value.variableName),
            ]),
          );
        } else if (
          DatetimeOptions.guard(options) &&
          DynamicValue.guard(options.dynamicDefault) &&
          options.dynamicDefault.variableName != null
        ) {
          referencedParams.push(
            ...createEmptyParamReferences([
              safeCastVariableName(options.dynamicDefault.variableName),
            ]),
          );
        }
      }
      return {
        newParams,
        referencedParams,
      };
    }
    case "SqlCell": {
      const refs = cellContents.cellReferencesV2;
      const newParams = [];
      const referencedParams = [];
      if (refs != null) {
        referencedParams.push(...(refs?.referencedParams ?? []));
      }
      newParams.push(
        ...createEmptyParamReferences([
          safeCastVariableName(cellContents.resultVariable),
        ]),
      );
      return mergeCellReferencesV2(
        cellContents.sqlDisplayTableConfig?.conditionalFormattingReferences,
        cellContents.sqlDisplayTableConfig?.filtersReferences,
        getCalcsCellReferencesV2(cellContents.sqlDisplayTableConfig?.calcs),
        { newParams, referencedParams },
      );
    }
    case "DbtMetricCell": {
      const newParams = [];
      newParams.push(
        ...createEmptyParamReferences([
          safeCastVariableName(cellContents.resultVariable),
        ]),
      );
      return mergeCellReferencesV2(
        cellContents.dbtMetricDisplayTableConfig
          ?.conditionalFormattingReferences,
        cellContents.dbtMetricDisplayTableConfig?.filtersReferences,
        // Calcs aren't enabled for DBT metric cells,
        // so this likely won't ever do anything, but is here for completeness
        getCalcsCellReferencesV2(
          cellContents.dbtMetricDisplayTableConfig?.calcs,
        ),
        { newParams, referencedParams: [] },
      );
    }
    case "VegaChartCell": {
      const variableNameList = getPrunedVariableNameList(cellContents.metadata);
      const referencedParams = [];
      for (const variableName of variableNameList) {
        referencedParams.push(
          ...createEmptyParamReferences([safeCastVariableName(variableName)]),
        );
      }
      return { referencedParams, newParams: [] };
    }
    case "MapCell": {
      const dfs = getMapReferencedDataFrames(cellContents.map.layers);
      const referencedParams = createEmptyParamReferences(
        [...dfs.map(({ name }) => name)].map((v) => safeCastVariableName(v)),
      );
      return { referencedParams, newParams: [] };
    }
    case "ChartCell": {
      const dfs = getChartReferencedInputs(cellContents.chartSpec);
      const referencedParams = createEmptyParamReferences(
        dfs.map((v) => safeCastVariableName(v)),
      );

      const { chartSpec, outputResult } = cellContents;
      const newVars =
        cell.parentBlockCellId == null &&
        // TODO: can get rid of chartSpec.settings.selectionEnabled check after
        // migrating all users to the filtering
        (outputResult ||
          (chartSpec.type !== "unsupported" &&
            chartSpec.settings.selectionEnabled))
          ? [safeCastVariableName(cellContents.resultVariable)]
          : [];

      return mergeCellReferencesV2(
        {
          newParams: createEmptyParamReferences(newVars),
          referencedParams: referencedParams,
        },
        cellContents.chartDisplayTableConfig?.conditionalFormattingReferences,
        cellContents.chartDisplayTableConfig?.filtersReferences,
        getCalcsCellReferencesV2(cellContents.chartDisplayTableConfig?.calcs),
      );
    }
    case "ExploreCell": {
      const referencedDfNames = new Set<VariableName>();
      const resultVariableNames = new Set<VariableName>();
      // TODO(EXPLORE): EXP-1135 - maybe include semantic dataset inputs as a reference
      if (NoCodeCellDataframe.guard(cellContents.exploreDataframe)) {
        referencedDfNames.add(
          safeCastVariableName(
            getNoCodeCellInputReference(cellContents.exploreDataframe),
          ),
        );
      }
      return mergeCellReferencesV2(
        {
          newParams: createEmptyParamReferences([...resultVariableNames]),
          referencedParams: createEmptyParamReferences([...referencedDfNames]),
        },
        cellContents.displayTableConfig.conditionalFormattingReferences,
        cellContents.displayTableConfig.filtersReferences,
        getCalcsCellReferencesV2(cellContents.displayTableConfig?.calcs),
      );
    }
    case "WritebackCell": {
      const referencedParams = createEmptyParamReferences(
        cellContents.dataframeName
          ? [safeCastVariableName(cellContents.dataframeName)]
          : [],
      );
      return { referencedParams, newParams: [] };
    }
    case "PivotCell": {
      const referencedParams = createEmptyParamReferences(
        cellContents.dataframe
          ? [
              safeCastVariableName(
                getNoCodeCellInputReference(cellContents.dataframe),
              ),
            ]
          : [],
      );
      const newParams = createEmptyParamReferences([
        safeCastVariableName(cellContents.resultVariable),
      ]);
      return mergeCellReferencesV2(
        cellContents.displayTableConfig?.conditionalFormattingReferences,
        cellContents.displayTableConfig?.filtersReferences,
        // Calcs aren't enabled for pivot cells,
        // so this likely won't ever do anything, but is here for completeness
        getCalcsCellReferencesV2(cellContents.displayTableConfig?.calcs),
        { newParams, referencedParams },
      );
    }
    case "FilterCell": {
      const referencedParams = createEmptyParamReferences(
        cellContents.dataframe
          ? [
              safeCastVariableName(
                getNoCodeCellInputReference(cellContents.dataframe),
              ),
            ]
          : [],
      );
      const newParams = createEmptyParamReferences([
        safeCastVariableName(cellContents.resultVariable),
      ]);
      return mergeCellReferencesV2(cellContents.filtersReferences, {
        referencedParams,
        newParams,
      });
    }
    case "ComponentImportCell": {
      return { referencedParams: [], newParams: [] };
    }
    case "CollapsibleCell": {
      return { referencedParams: [], newParams: [] };
    }
    case "BlockCell": {
      return { referencedParams: [], newParams: [] };
    }
    default:
      assertNever(cellContents, cellContents);
  }
};

// This should have the same logic as getCellReferenceMap in the server
// This is only used for display things in the UI currently, so if there are inconsistencies it is not catastrophic
// If this becomes used for creating mutation/MP operations, then we should make 100% sure its consistent with backend so it doesn't get out of sync
export const getCellReferenceMap = (
  cellsMap: Record<CellId, CellMP | undefined>,
  cellContentsMap: Record<CellId, CellContentsMP | undefined>,
): [CellReferenceMap<CellMP>, Set<string>] => {
  const cellMap: CellReferenceMap<CellMP> = {};
  const importsSet = new Set<string>();

  for (const cell of Object.values(cellsMap)) {
    if (!cell || cell.deletedDate != null) {
      continue;
    }
    const cellContents = cellContentsMap[cell.id];
    if (!cellContents || cellContents.deletedDate != null) {
      continue;
    }
    let references = getCellReferencesV2(cell, cellContents);
    if (PythonCellReferencesV2.guard(references)) {
      references.imports.forEach((i) => importsSet.add(i));
    }
    if (references == null) {
      references = {
        referencedParams: [],
        newParams: [],
      };
    }
    cellMap[cell.id] = {
      cell,
      references,
    };
  }
  return [cellMap, importsSet];
};
