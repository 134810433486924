import {
  CellId,
  GraphNodeV3,
  GraphNodeV3Id,
  HexVersionId,
  upgradeGraphNodeV2,
} from "@hex/common";
import { useCallback } from "react";

import { useSelector, useStore } from "../redux/hooks";
import { cellGraphSelectors } from "../redux/slices/cellGraphSlice";
import {
  CellMP,
  hexVersionMPSelectors,
} from "../redux/slices/hexVersionMPSlice";
import { projectGraphV3Selectors } from "../redux/slices/projectGraphV3Slice";
import { RootState } from "../redux/store";
import { useProjectContext } from "../util/projectContext";

export type UseGraphNodeV3SelectorArgs<T> =
  | {
      nodeId: GraphNodeV3Id;
      selector: (cellContents: GraphNodeV3<CellMP>) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe?: false;
    }
  | {
      nodeId: GraphNodeV3Id | undefined;
      selector: (cellContents: GraphNodeV3<CellMP> | undefined) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe: true;
    };

// Automatically upgrade old GraphNodeV2 -> GraphNodeV3 in place depending on hexVersion setting
function getGraphNodeV3({
  hexVersionId,
  nodeId,
  state,
}: {
  state: RootState;
  hexVersionId: HexVersionId;
  nodeId: GraphNodeV3Id;
}): GraphNodeV3<CellMP> | null | undefined {
  const { graphV3 } = hexVersionMPSelectors
    .getHexVersionSelectors(hexVersionId)
    .select(state);
  if (graphV3) {
    return (
      projectGraphV3Selectors
        .getGraphNodeV3Selectors(hexVersionId)
        .selectById(state, nodeId) ?? null
    );
  } else {
    const graphNodeV2 = cellGraphSelectors
      .getGraphNodeSelectors(hexVersionId)
      .selectById(state, nodeId);
    if (graphNodeV2 != null) {
      return upgradeGraphNodeV2(graphNodeV2);
    }
    return null;
  }
}

export function useGraphNodeV3Selector<T>(
  args: UseGraphNodeV3SelectorArgs<T>,
): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    if (args.nodeId == null) {
      if (args.safe) {
        return args.selector(undefined);
      } else {
        throw new Error(`Missing graph node for id: ${args.nodeId}`);
      }
    }
    const graphNodeV3 = getGraphNodeV3({
      state,
      hexVersionId,
      nodeId: args.nodeId,
    });

    if (graphNodeV3 == null) {
      if (args.safe) {
        return args.selector(undefined);
      } else {
        throw new Error(`Missing graph node for id: ${args.nodeId}`);
      }
    }

    return args.selector(graphNodeV3);
  }, args.equalityFn);
}

interface UseUnsafeGraphNodeV3GetterArgs<A extends unknown[], T> {
  selector?: (cell: GraphNodeV3<CellMP>, ...args: A) => T;
  safe?: false;
}
interface UseSafeGraphNodeV3GetterArgs<
  A extends unknown[],
  T = GraphNodeV3<CellMP>,
> {
  selector?: (cell: GraphNodeV3<CellMP> | undefined, ...args: A) => T;
  safe: true;
}
export type UseGraphNodeV3GetterArgs<A extends unknown[], T> =
  | UseUnsafeGraphNodeV3GetterArgs<A, T>
  | UseSafeGraphNodeV3GetterArgs<A, T>;

type UseUnsafeGraphNodeV3GetterResult<A extends unknown[], T> = (
  cellId: CellId,
  ...args: A
) => T;
type UseSafeGraphNodeV3GetterResult<A extends unknown[], T> = (
  cellId: CellId | undefined,
  ...args: A
) => T;
export type UseGraphNodeV3GetterResult<A extends unknown[], T> =
  | UseUnsafeGraphNodeV3GetterResult<A, T>
  | UseSafeGraphNodeV3GetterResult<A, T>;

export function useGraphNodeV3Getter<
  A extends unknown[],
  T = GraphNodeV3<CellMP>,
>(
  args?: UseUnsafeGraphNodeV3GetterArgs<A, T>,
): UseUnsafeGraphNodeV3GetterResult<A, T>;
export function useGraphNodeV3Getter<
  A extends unknown[],
  T = GraphNodeV3<CellMP> | undefined,
>(
  args: UseSafeGraphNodeV3GetterArgs<A, T>,
): UseSafeGraphNodeV3GetterResult<A, T>;
export function useGraphNodeV3Getter<A extends unknown[], T>(
  args: UseGraphNodeV3GetterArgs<A, T | GraphNodeV3<CellMP> | undefined> = {},
): UseGraphNodeV3GetterResult<A, T | GraphNodeV3<CellMP> | undefined> {
  const { hexVersionId } = useProjectContext();
  const store = useStore();

  return useCallback(
    (nodeId: GraphNodeV3Id | undefined, ...cbArgs: A) => {
      if (nodeId == null) {
        if (args.safe) {
          const selector = args.selector;
          return selector?.(undefined, ...cbArgs);
        } else {
          throw new Error(`Missing graph node for id: ${nodeId}`);
        }
      }

      const graphNodeV3 = getGraphNodeV3({
        state: store.getState(),
        hexVersionId,
        nodeId,
      });

      if (graphNodeV3 == null) {
        if (args.safe) {
          const selector = args.selector;
          return selector?.(undefined, ...cbArgs);
        } else {
          throw new Error(`Missing graph node for id: ${nodeId}`);
        }
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      const selector = args.selector;
      return selector ? selector(graphNodeV3, ...cbArgs) : graphNodeV3;
    },
    [hexVersionId, store, args.safe, args.selector],
  );
}
